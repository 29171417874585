/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from '../../utils/functions';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import LinkWithArrow from '../../components/link-with-arrow';
import BoxArticle from '../../components/box-article';
import Link from './../../components/link';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import ContactForm from '@blocks/contact-form';

import './style.scss';

/**
 * Single Post.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const LegalPrivacy = ({ pageContext }) => {
	const { page: { title, content, legalPrivacyMeta, seo, uri }, childPages } = pageContext;

	const privacyChildPages = childPages.filter(item => 'en' === item.language.slug);

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title={title} uri={uri} className="page legal-and-privacy">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<div className="blog-header">
				<h1 className="blog-header__title blog-header__title--legal-privacy">{title}</h1>
				<div dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
			</div>
			<div className="row">
				{privacyChildPages && privacyChildPages.length && privacyChildPages.map(childPage => (
					<div className="small-12 medium-6 column" key={childPage.id}>
						<Link url={childPage.uri} className="blog-header__box-link" external={false} title={sanitize(childPage.title)}>
							<BoxArticle
								post={childPage}
							/>
						</Link>
					</div>
				))}
			</div>
			<div className="blog-links">
				<LinkWithArrow text={legalPrivacyMeta.readAboutLinkText} link={legalPrivacyMeta.readAboutLink.uri} />
			</div>
			<ContactForm />
		</Layout>
	);
};

LegalPrivacy.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default LegalPrivacy;
